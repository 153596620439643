<template>
    <v-row justify="center">
     <v-dialog
       v-model="dialog"
       persistent
       top
        max-width="1000px"
       @input="checkDialogClose"
     >
     
       <v-card>
         <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
           <div>
               Email Preview
           </div>
           <v-btn icon @click="handleCloseDialog">
             <v-icon>mdi-close</v-icon>
         </v-btn>
         </v-card-title>
         <v-card-text class="px-4 mt-4">
            <div class="mail-info">
                <p class="ma-0">From : <strong>{{email_sender}}</strong> </p>
                <p class="ma-0">To : <strong>{{billing_email}}</strong></p>
                <p class="ma-0" v-if="cc">Cc : <strong>{{handleGetCc()}}</strong></p>
            </div>
           <div class="html-content mt-4" v-html="billing_content"></div>
         </v-card-text>
         <v-card-actions  class="grey lighten-2">
           <v-spacer></v-spacer>
           <v-btn
             depressed
             color="primary"
             @click="handleSendBill"
             :loading="loading"
             >
             Send <v-icon class="ml-2">mdi-send</v-icon>
             </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
   </template>
  
  <script>
  export default {
     props: {
         open_modal: {
             type: Boolean,
         },
         billing_content:{},
         email_sender:{},
         billing_email:{},
         cc:{},
     },
     data () {
         return {
             dialog: false,
             loading: false,
             valid: true,
             nameRules: [
                 v => !!v || 'Company Name is required',
             ],
             emailAddressRules: [
                 v => !!v || 'Email Address is required',
                 v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
             ],
             
  
             payload : {
                  company_name : '',
                  email_address : '',
                  user_id : this.$store.getters.user.user.id,
                  company_id : this.$store.getters.user.default_user_company.company_id, 
             }
         }
     },
     methods:{
         handleSendBill() {
            this.$emit('sendBill')
         },
         handleGetCc() {
            if(this.cc){
                const cc = JSON.parse(this.cc)
                return cc.join(', ');
            } 
         },
         checkDialogClose(val) {
             if(!val){
                 this.$emit('handleModalHide')
             }
         },
         handleCloseDialog() {
                 this.$emit('handleModalHide')
         },
         handleValidation(type){
             return [
                 v => !!v || `${type} is required`,
                 v =>  /.+@.+\..+/.test(v) || 'E-mail must be valid',
             ];
         },
         handleReset(){
          this.payload = {
              company_name : '',
              email_address : '',
              user_id : this.$store.getters.user.user.id,
          }
        //   this.$refs.form.resetValidation()
         }
     },
     watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
             if(!newValue){
                  this.handleReset();
             }
         },
         'selected_item'(newValue) {
              this.payload = {
                  id : newValue.id,
                  company_name : newValue.company_name,
                  email_address : newValue.email_address,
                  user_id : this.$store.getters.user.user.id,
              };
           }
     }
  }
  </script>
<style>
.html-content *{
    color: #000;
}
#wrapper p{
   margin: 0  !important;
}
.mail-info{
    color: #000;
    padding: 0 20px;
    font-size: 15px;
}
</style>