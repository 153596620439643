<template>
    <v-row justify="center">
     <v-dialog
       v-model="dialog"
       persistent
       top
       max-width="600px"
       @input="checkDialogClose"
     >
     
       <v-card>
         <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
           <div>
              Download Logs
           </div>
           <v-btn icon @click="handleCloseDialog">
             <v-icon>mdi-close</v-icon>
         </v-btn>
         </v-card-title>
         <v-card-text class="px-4 pb-0 my-4">
            <div>
                <p class="text-h5" style="color: #000;"><strong>{{file && file.file_name}}</strong></p>
            </div>
            <table class="table table-bordered">
                <thead>
                    <th>
                            User
                    </th>
                    <th>
                        Download Counts
                    </th>
                </thead>
                <tbody >
                    <template v-if="download_logs">
                        <tr v-for="(item,index) in download_logs" :key="index">
                            <td>{{index}}</td>
                            <td>
                                <v-badge 
                                    color="red"
                                    :content="item"
                                    >
                                </v-badge>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
         </v-card-text>
         <v-card-actions>
           <!-- <v-spacer></v-spacer>
           <v-btn
             depressed
             color="primary"
             @click="handleSumbit"
             >
             Add
             </v-btn> -->
         </v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
   </template>
  
  <script>
  export default {
     props: {
         open_modal: {
             type: Boolean,
         },
         download_logs: {
             type: Array,
         },
         file: {
             type: Array,
         },
     },
     data () {
         return {
             dialog: false,
             valid: true,
             nameRules: [
                 v => !!v || 'Branch Name is required',
             ],
             payload : {
                  branch_name : '',
                  company_id : null,
                  user_id : this.$store.getters.user.user.id,
             }
         }
     },
     methods:{
         handleSumbit() {
             if(this.$refs.form.validate()){
                 this.axios.post('/company/branch',{...this.payload, company_id: this.company_id}).then((res)=>{
                    this.$awn.success('Branch Added Successfully');
                     if(res){
                         this.$emit('handleModalHide');
                         this.$emit('handleFetchBranch');
                         this.handleReset();
                     }
                 }).catch((error)=>{
                     console.log(error,'error')
                 })
             }
         },
         checkDialogClose(val) {
             if(!val){
                 this.$emit('handleModalHide')
             }
         },
         handleCloseDialog() { 
                 this.$emit('handleModalHide')
         },
         handleValidation(type){
             return [
                 v => !!v || `${type} is required`,
                 v =>  /.+@.+\..+/.test(v) || 'E-mail must be valid',
             ];
         },
         handleReset(){
          this.payload = {
              branch_name : '',
              user_id : this.$store.getters.user.user.id,
          }
         }
     },
     watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
         },
        //  'company_id'(newValue) {
        //      this.payload.company_id = newValue;
        //  },
         'item_billing_selected'(newValue) {
              this.payload = {
                  id : newValue.id,
                  company_name : newValue.company_name,
                  email_address : newValue.email_address,
                  user_id : this.$store.getters.user.user.id,
              };
           }
     }
  }
  </script>

<style scoped>
    table {
    width: 100%;
    border-collapse: collapse;
    }
    td, th {
    border: 1px solid #ddd;
    padding: 8px;
    width: 50%;
    text-align: center;
    color: #000;
    }
    tr:nth-child(even) {
    background-color: #f2f2f2;
    }
    th {
    background-color: #4CAF50;
    color: white;
    }
</style>