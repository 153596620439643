import Vue from 'vue'
import App from './layout/IndexLayout.vue';
// import App from './App.vue'
import axios from './utils/axios'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
import './permision' // permission control
// import { createPinia } from "pinia";
import './assets/css/custom.css'
import '@fortawesome/fontawesome-free/css/all.css';
import VueAWN from "vue-awesome-notifications"
import Notiflix from 'notiflix';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import * as utils from '@/utils/dateHelper';
import '@mdi/font/css/materialdesignicons.min.css'

import VueMeta from 'vue-meta';

Vue.use(VueMeta);

// const pinia = createPinia();

let options = {
    labels: {
        success: 'Success!',
        warning: 'Warning!',
        alert: 'Failed!',
        confirm: 'Failed!',
        error: 'Error!',
        async: "Loading",
    },
    position: 'top-right',
    
    durations: {
        global: 3000
    }
}

Notiflix.Loading.init({
    className: 'notiflix-loading',
    zindex: 4000,
    backgroundColor: 'rgba(252, 253, 255,1)',
    rtl: false,
    fontFamily: 'Quicksand',
    cssAnimation: true,
    cssAnimationDuration: 400,
    clickToClose: false,
    customSvgUrl: null,
    customSvgCode: null,
    svgSize: '70px',
    svgColor: '#4096ff',
    messageID: 'NotiflixLoadingMessage',
    messageFontSize: '15px',
    messageMaxLength: 34,
    messageColor: '#000',
    failure: {
      background: '#F6C6C6',
      textColor: '#C34A36'
    },
    success: {
      background: '#C3F6C6',
      textColor: '#2A7F2A'
    }
});
Notiflix.Confirm.init({
    className: 'notiflix-confirm',
    width: '350px',
    zindex: 4003,
    position: 'center',
    distance: '10px',
    backgroundColor: '#f8f8f8',
    borderRadius: '25px',
    backOverlay: true,
    backOverlayColor: 'rgba(0,0,0,0.5)',
    rtl: false,
    cssAnimation: true,
    cssAnimationDuration: 300,
    cssAnimationStyle: 'fade',
    plainText: true,
    titleColor: '#000',
    titleFontSize: '20px',
    titleMaxLength: 34,
    messageColor: '#1e1e1e',
    messageFontSize: '16px',
    messageMaxLength: 110,
    buttonsFontSize: '15px',
    buttonsMaxLength: 34,
    okButtonColor: '#f8f8f8',
    okButtonBackground: 'blue',
    cancelButtonColor: '#f8f8f8',
    cancelButtonBackground: '#a9a9a9',
});

Vue.use(VueAWN, options)
Vue.use(VueViewer)

Vue.prototype.$notiflix = Notiflix;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

const whiteList = ['/login', '/adminlogin','/forgot_password', '/verify_token', '/verify_social_oauth', '/web/verify']

router.beforeResolve((to, from, next) => {
    var user = store.getters.user;
    if (whiteList.indexOf(to.path) !== -1) {
        next()
    } else {
        if (!user) {
            next('/login');
        } 
        else if(user && user.default_user_company.role != 'SA' && user.default_user_company.role != 'CA'  && to.meta.requiresRole){
            next('/');
        }
        else {
            if(to.meta.requireIsAdmin){
                if(user.user.is_admin){
                    next();
                }
                else{
                    next('/billing');
                }
            }
            else{
                next();
            }
        }
    }

});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


