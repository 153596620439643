<template>
    <div class="request-modal-wrapper">
    <v-dialog
       v-model="dialog"
       persistent
       top
       max-width="1500px"
       @input="checkDialogClose"
     >
     
       <v-card>
         <v-card-title class="text-h5 grey lighten-2 d-flex" style="justify-content: space-between;">
           <!-- <div>
            <v-btn color="primary" @click="handleSendBill">
                Resend
                <v-icon size="18" class="ml-2">mdi-send</v-icon>
            </v-btn>
           </div> -->
           <div>
                <h5 style="font-size: 20px;">Requests</h5> 
           </div>
            <v-btn icon @click="handleHideModal">
             <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text style="padding: 0">
            <div>
                <v-card class="p-5 v-card-container">
                    <div class="d-flex justify-md-space-between align-center flex-wrap mb-4" style="gap: 30px">
                        <div class="d-flex flex-row flex-grow-1 align-center" style="gap:15px">
                            <label>
                                <h5 style="font-size: 18px;">Filter</h5>
                            </label>
                            <v-autocomplete
                            :items="aetos_payment_proof"
                            v-model="query.aetos_payment_proof"
                            text="text"
                            id="value"
                            hide-details
                            label="Aetos proof of payment"
                            solo
                            dense
                            clearable
                            @change="getRequests()"
                            ></v-autocomplete>
                        </div>
                        <div class="search-field d-flex align-center flex-grow-1 container-search">
                            <v-text-field
                                class="w-30"
                                v-model="query.search"
                                label="Search"
                                hide-details
                                clearable
                                @change="getRequests()"
                                @click:clear="handleGetRequests()"
                                dense
                                solo
                            ></v-text-field>
                            <v-btn
                                class="btn-search"
                                color="default"
                                elevation="2"
                                @click="getRequests()"
                                >
                                Search
                            </v-btn>
                        </div>
                    </div>
                    <div class="show-cancelled-checkbox">
                      <v-checkbox
                        class="checkbox"
                        @change="getRequests()"
                        v-model="query.show_related_to_attachment"
                        color="red"
                      >
                        <template v-slot:label>
                          <span class="custom-label" style="color:#000; font-weight: 500;">Show related</span>
                          <v-tooltip right max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on"  style="cursor: pointer;" class="ml-2">
                                        <v-icon size="20">mdi-information</v-icon>
                                    </div>
                                </template?>
                                <span>Show the request with the attachment.</span>
                            </v-tooltip>
                        </template>
                      </v-checkbox>
                    </div>
                    <div>
                        <v-data-table
                            :headers="headers"
                            :items="datas.data"
                            :page.sync="page"
                            :items-per-page="10"
                            hide-default-footer
                            calculate-widths
                            class="elevation-1"
                            show-select
                            v-model="selected"
                            @page-count="pageCount = $event"
                            :item-class="rowClass"
                            @click:row="handleRowClick"
                            :loading="table_loading"
                        >
                        <template  v-slot:item.created_at="{ item }">
                            {{formattedDate(item.created_at)}}
                        </template>

                        <template v-slot:item.requestor="{ item }">
                                {{item.user.full_name}}
                        </template>

                        <template v-slot:item.company="{ item }">
                                    {{item.sub_company.name}}
                        </template>
                        
                        <template v-slot:item.branch="{ item }">
                                {{item.user.client_company.branch ? item.user.client_company.branch.branch_name : "--"}}
                        </template>

                        <template v-slot:item.entity_id="{ item }">
                                {{ item.entity.name }}
                        </template>

                        <template v-slot:item.start_date="{ item }">
                            {{ item.start_date && $utils.changeDateFormat(item.start_date) }}
                        </template>

                        <template v-slot:item.in_charge="{ item }">
                                {{ item.in_charge.first_name }} {{ item.in_charge.last_name }}
                        </template>
                        
                        <template v-slot:item.noa_amount="{ item }">
                                {{ formatNumber(item.noa_amount) }}
                        </template>
                        <template v-slot:item.insured_amount="{ item }">
                                {{ formatNumber(item.insured_amount) }}
                        </template>
                        <template v-slot:item.net_premium="{ item }">
                            {{ formatNumber(item.net_premium )}}
                        </template>

                        <template v-slot:item.project_name="{ item }">
                            <v-tooltip left max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on"  style="cursor: pointer;">{{ truncatedText(item.project_name )}}</div>
                                </template?>
                                <span>{{item.project_name}}</span>
                            </v-tooltip>
                        
                        </template>

                        <template v-slot:item.product_classification="{ item }">
                            <v-tooltip left max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on"  style="cursor: pointer;">{{ truncatedText(item.product_classification )}}</div>
                                </template?>
                                <span>{{item.product_classification}}</span>
                            </v-tooltip>
                            
                        </template>

                        <template v-slot:item.insurance_status="{ item }">
                            {{ handleGetInsuranceStatus(item.insurance_status) }}
                        </template>
                        <template v-slot:item.payment_status="{ item }">
                            {{ handleGetPaymentStatus(item.payment_status) }}
                        </template>

                        <!-- <template v-slot:item.action="{ item }">
                            <div class="d-flex">
                                <v-btn color="primary" icon small @click="goTo(item.id)">
                                <v-icon size="18">mdi-eye</v-icon>
                                </v-btn>
                                <template  v-if="item.user_id == user.id && item.insurance_status == 1">
                                |
                                <v-btn color="primary" icon small @click="handleDelete(item.id)">
                                    <v-icon size="18" color="red">mdi-delete</v-icon>
                                </v-btn> 
                                </template>
                            </div>
                        </template> -->
                                                                
                        </v-data-table>
                    <div class="text-center pt-2">
                        <v-pagination
                        v-model="page"
                        @input="handlePageChange"
                        :length="datas.last_page"
                        ></v-pagination>
                    </div>
                    </div>
                </v-card>
            </div>
         </v-card-text>
         <v-card-actions class="text-h5 grey lighten-2 d-flex">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="handleAddToTheList">
                <v-icon>mdi-plus</v-icon>
                Save
            </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
    </div>
</template>

<script>
  import { changeFormat } from '@/utils/dateHelper';
  import { payment_status } from  '@/helpers/payment_status';
  import { insurance_status } from  '@/helpers/insurance_status';
  import { EventBus } from '@/utils/eventBus'; // Adjust the path as necessary
  export default {
    components:{
    },
    props: {
         open_modal: {
             type: Boolean,
         },
         attachment_id: {},
     },
    data () {
      return {
        // userStore : useUserStore().user,
        search:null,
        selected:[],
        showeducationmodal:false,
        export_modal:false,
        table_loading:false,
        dialog:false,
        showeligibilitymodal:false,
        page: 1,
        pageCount: 0,
        
        itemsPerPage: 10,
        user: this.$store.getters.user.user,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'DATE CREATED', value: 'created_at' },
          { text: 'START DATE', value: 'start_date' },
          { text: 'Policy No.', value: 'policy_no' },
          { text: 'Validity', value: 'validity' },
          { text: 'Product Classification', value: 'product_classification' },
          { text: 'IN CHARGE', value: 'in_charge' },
          { text: 'COMPANY', value: 'company' },
          // { text: 'BRANCH', value: 'branch' },
          { text: 'ENTITY', value: 'entity_id' },
        //   { text: 'NAME OF PROJECT', value: 'project_name' },
          { text: 'AMOUNT (NOA)', value: 'noa_amount' },
          { text: 'INSURED', value: 'insured_amount' },
          { text: 'NET PREMUIM', value: 'net_premium' },
          { text: 'INSURANCE STATUS', value: 'insurance_status' },
          { text: 'PAYMENT STATUS', value: 'payment_status' },
        //   { text: 'ACTION', align:'center', value: 'action' },
        ],
        datas:[],
        summary:null,
        payment_status:payment_status,
        insurance_status:insurance_status,
        aetos_payment_proof :[
            { value: 1, text: 'Unpaid by AETOS' },
            // { value: 3, text: 'PROOF OF LBC' },
            { value: 2, text: 'Paid by AETOS' },
        ],
        query:{
          search : null,
          payment_status : null,
          insurance_status : null,
          in_home_page : true,
          show_cancelled : false,
          aetos_payment_proof : null,
        }
      }
    },
    created(){
      if(!this.$store.getters.user.user.is_admin){
        if(this.$store.getters.user.user.default_user_company.role  == 'SA'){
          const header = this.headers.filter(header => {
              return header.value !== 'company'
            });
          return this.headers = header;
        }
        else{
          const header = this.headers.filter(header => {
              return header.value !== 'company' && header.value !== 'branch'
            });
          return this.headers = header;
        }
      }
    } , 

    mounted(){
      this.getRequests();
      EventBus.$on('refreshPage', this.refreshPage);
    },
    beforeDestroy() {
      EventBus.$off('refreshPage'); // Clean up
    },

    methods:{
     checkDialogClose(val) {
        if(!val){
            this.$emit('handleModalHide')
        }
     },
     handleHideModal() {
        this.$emit('handleModalHide')
     },
     handleAddToTheList() {
            this.$emit('handleSaveSelected',this.selected)
     },
      refreshPage() {
        this.reset();
        this.getRequests();
      },
      reset() {
        this.query = {
          search : null,
          payment_status : null,
          insurance_status : null,
          in_home_page : true,
          show_cancelled : false,
        };
      },
      handleRowClick(item) {
       this.goTo(item.id)
      },
      rowClass(item) {
        if (item.insurance_status == 9) {
          return 'application for-acceptance';
        }
        else if(item.insurance_status == 7 || item.insurance_status == 8){
          return 'application cancelled';
        }
        else {
          return null
        }
      },
      truncatedText(value,maxLength = 50) {
        if (value.length > maxLength) {
          return value.slice(0, maxLength) + '...';
        }
        return value;
      },
      formatNumber(value) {
        // Format the number with commas
            if (typeof value !== 'string') {
                value = String(value || ''); // Ensure value is a string
            }
            const [integerPart, decimalPart] = value.split('.');
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

       },
        filteredHeaders() {
          const header = this.headers.filter(header => {
            return header.value !== 'company'
          });
          this.headers = header;
        },
        handleExportDate(date_range) {
         this.handleExport(date_range)
        },
        handleFilterInsuranceStatus(value) {
         this.query.search = null
         this.query.payment_status = null
         this.query.insurance_status = value
         this.getRequests()
        },
        handleFilterpaymentStatus(value) {
         this.query.search = null
         this.query.insurance_status = null
         this.query.payment_status = value
         this.getRequests()
        },
        handleFilter() {
          console.log(this.query)
        },
        handleDelete(id) {
          this.$notiflix.Confirm.show(
              'DELETE',
              'Do you want to delete this item?',
              'Yes',
              'No',
              () => {
                  this.handleDeleteRequest(id)
              },
          );
        },
        formattedDate(date) {
          return changeFormat(date); // Example format string
        },
        handleGetPaymentStatus(id) {
         const status_payment = payment_status.find(item=>item.value == id);
         return status_payment.text
        },
        handlePageChange() {
          this.getRequests();
        },
        handleGetInsuranceStatus(id) {
         const status = insurance_status.find(item=>item.value == id);
         return status.text
        },
        addApplication(){
            this.$router.push(`/application`)
        },
        goTo(id){
            this.$router.push(`/application/view/${id}`)
        },
        handleGetRequests(){
            const timeout = setTimeout(() => {
                this.getRequests();
                clearTimeout(timeout);
            }, 200);
        },
        getRequests(){
           this.table_loading = true
           const query = {...this.query, attachment_id : this.attachment_id}
           this.axios.get(`/request?page=${this.page}&user_company_id=${this.user.current_user_company_id}&query=${JSON.stringify(query)}`).then((res)=>{
                this.datas = res.data.data;
                this.summary = res.data.summary;
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.table_loading = false
           })
        },
        handleExport(date_range){
           this.$notiflix.Loading.arrows('Exporting...');
           this.axios.get(`/request/export?user_company_id=${this.user.current_user_company_id}&query=${JSON.stringify({...this.query, ...date_range})}`,{
              responseType: 'blob',
           }).then((response)=>{
              if(response.status == 200){
                      const url = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 'request.csv'); 
                      document.body.appendChild(link);
                      link.click();
              }
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
        },
        handleDeleteRequest(id){
           this.$notiflix.Loading.arrows();
           this.axios.delete(`/request/${id}`).then((res)=>{
             this.$awn.success('Request Deleted Successfully')
             this.getRequests();
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
        },
    },

    watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
         },
     }
  }
</script>

<style scoped>
.v-card-container{
    max-height: 600px;
    overflow: auto;
}
</style>