<template>
    <v-row justify="center">
      <div v-if="dialog">
      <v-dialog
        class="request-duplicate-confirmation"
        v-model="dialog"
        max-width="700"
        @input="checkDialogClose"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5" style="font-weight: 600">
            <div class="text-center">
                Template
            </div>
            <div class="ml-auto">
                <v-btn icon @click="handleCloseModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
          </v-card-title>
          <hr class="mx-6 mb-5" style="color: #fff;"/>

          <v-card-text>
            <section class="template-paramaters mb-4">
                <h5 className='m-0'>$company$ &nbsp;<span>(Refers to the company)</span></h5>
                <h5 className='m-0'>$requests$ &nbsp;<span>(Refers to the list of requests)</span></h5>
                <h5 className='m-0'>$total$ &nbsp;<span>(Refers to the total amount unpaid)</span></h5>
            </section>
            <section class="cc-form d-flex " style="gap: 10px" v-if="show_add_cc">
                <div class="d-flex" style="width: 100%;">
                    <v-text-field
                    label="CC : "
                    height="20px"
                    placeholder="Enter to add cc"
                    v-model="email"
                    :error-messages="emailErrors"
                    outlined
                    dense
                    clearable
                    @keydown.enter="handleAddCC"
                    >
                    </v-text-field>
                </div>
                <v-btn color="error" style="margin-top: 5px;" icon small rounded @click="show_add_cc =! show_add_cc, emailErrors = [], email=null">
                    <v-icon size="25">mdi-close-circle</v-icon>
                </v-btn>
            </section>
            <section class="cc-wrapper">
                <div class="header d-flex align-center">
                    <h5 className='m-0' style="margin-right: 10px; color: #000; font-size: 15px;">CC</h5>
                    <v-btn color="primary" small v-if="!show_add_cc" @click="show_add_cc =! show_add_cc">
                        Add
                    </v-btn>
                </div>
                <div class="cc-container">
                    <template v-if="datas.cc.length">
                        <div class="d-flex lists" v-for="(item,index) in datas.cc" :key="index">
                            <h5 className='m-0' style="margin-right: 10px;">{{item}}</h5>
                            <v-icon size="18" @click="hanleRemoveFromCC($event,index)">mdi-close</v-icon>
                        </div>
                    </template>
                    <template v-else>
                        <div class="d-flex no-emails">
                            No emails
                        </div>
                    </template>
                </div>
               
            </section>
            <div class="mt-4">
                <div ref="editor" class="quill-editor"></div>
            </div>
          </v-card-text>
          <v-card-actions  style="gap:20px">
            <v-btn
              style="width: fit-content; margin-left: auto;"
              color="primary"
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    </v-row>
  </template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import styles

export default {
    name:'DuplicateSentRequest',
    props: {
        open_modal: {
             type: Boolean,
        },
        item_selected: {}
    },
    data () {
        return {
             dialog: false,
             total: 0,
             emailErrors: [],
             email : null,
             datas:{
                id:null,
                content: null, // To store editor content
                cc: [], // To store editor content
             },
             show_add_cc : false,
             quill: null,
          
        }
    },

    beforeDestroy() {
        this.cleanupQuill(); // Ensure cleanup when component is destroyed
    },

    methods:{
        initializeQuill() {
            if (!this.quill) {
                this.quill = new Quill(this.$refs.editor, {
                    theme: 'snow',
                });

                this.quill.on('text-change', () => {
                    this.datas.content = this.quill.root.innerHTML;
                });
            }
        },

        getContent() {
            return this.quill.root.innerHTML; // Method to get content
        },

        formatNumber(value) {
        // Format the number with commas
            if (typeof value !== 'string') {
                value = String(value || ''); // Ensure value is a string
            }
            const [integerPart, decimalPart] = value.split('.');
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

       },
        checkDialogClose(val) {
           if(!val){
               this.$emit('handleModalHide')
           }
       },
       handleCloseModal() {
            this.$emit('handleModalHide')
       },
       handleSave() {
           this.$emit('handleSaveTemplate',this.datas)
       },
       handleAddCC(e) {
           const value = e.target.value
           if(this.checkEmail(value)){
            this.datas.cc.push(value);
            this.email = null
           }
       },
       hanleRemoveFromCC(e, index) {
        this.datas.cc.splice(index, 1)
       },

       checkEmail() {
            this.emailErrors = []; // Clear previous errors
            const exists = this.datas.cc.find(item=>item == this.email);
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!this.email) {
                this.emailErrors.push('Email is required.');
            } else if (!emailRegex.test(this.email)) {
                this.emailErrors.push('Invalid email format.');
            } else if (exists) {
                this.emailErrors.push('Email Already Exists');
            } else {
               return true
            }
            return false;
        },
       cleanupQuill() {
            if (this.quill) {
                this.quill =  null; // Dereference the instance
            }
        },
    },

    watch: {
        'open_modal'(newValue) {
            this.dialog = newValue;
            if (newValue) {
                this.$nextTick(() => {
                    this.initializeQuill(); // Initialize Quill when modal opens
                });
            } else {
                this.$nextTick(() => {
                    this.cleanupQuill(); // Clean up Quill instance
                });
            }
        },
        
        'item_selected'(newValue) {
           this.datas = newValue
           this.datas.cc = newValue.cc ? JSON.parse(newValue.cc) : []
        },
        // datas(newValue) {
        //     if(this.quill){
        //         console.log(this.quill.root.innerHTML,'this.quill.root.innerHTML')
        //     }
        //     if (this.quill && this.quill.root.innerHTML !== newValue.content) {
        //         console.log(newValue,'newValue')
        //         this.quill.root.innerHTML =  newValue.content; // Update editor content when prop changes
        //     }
        // },
        quill(newValue) {
            if(!newValue){
                return false;
            }
            if (this.quill && this.quill.root.innerHTML !== this.datas.content) {
                this.quill.root.innerHTML =  this.datas.content; // Update editor content when prop changes
            }
        },
    }
}
</script>

<style scoped>
.quill-editor {
  height: 300px;
}
.template-paramaters {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
}
.template-paramaters h5 {
    font-size: 14px;
}
.template-paramaters .h5 span, .template-paramaters h5 span {
    color: #d2a951;
}
.cc-wrapper{
    display: flex;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .15);
    min-height: 50px;
    max-height: 250px;
    flex-wrap: wrap;
    margin-top: -6px;
}
.cc-wrapper .header{
    justify-content: space-between;
    width: 100%;
    background-color: #f2f0f0;
    padding: 6px;
}
.cc-container{
    display: flex;
    gap: 10px;
    min-height: 50px;
    max-height: 250px;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
}
.cc-container .no-emails{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cc-wrapper .lists {
    width: fit-content;
    color: #181818;
    padding: 2px 10px;
    border-radius: 7px;
    border: solid 1px #c5c5c5;
    height: fit-content;
}
.cc-wrapper .lists h5{
    font-size: 14px;
}
.cc-wrapper .lists i{
    cursor: pointer;
}
.cc-wrapper .lists i:hover{
    color: #000;
}

</style>
<style >
.ql-editor > *{
    color: #000 !important;
}
</style>