<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="1500"
        @input="checkDialogClose"
      >
        <v-card>
            <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between elevation-1">
                <div>
                    Related requestsx
                </div>
                <v-btn icon @click="handleCloseDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="pa-2">
                <div>
                    <p class="confirm-text my-2" style="font-size: 18px;"><strong>Total : {{formatNumber(this.total)}}</strong></p> 
                </div>
                <v-data-table
                :headers="headers"
                :items="datas"
                :items-per-page="5"
                hide-default-footer
                v-model="selected"
                show-select
                class="elevation-1"
                >
                    <template v-slot:item.created_at="{ item, index }">
                            {{ formattedDate(item.created_at) }}
                    </template>
                    <template v-slot:item.net_premium="{ item, index }">
                            {{ formatNumber(item.net_premium) }}
                    </template>

                    <template v-slot:item.payment_status="{ item }">
                        {{ handleGetPaymentStatus(item.payment_status) }}
                    </template>

                </v-data-table>
            </v-card-text>
            <v-card-actions class="mt-4">
            <v-spacer></v-spacer>
                <v-btn
                depressed
                color="primary"
                @click="handleSumbit"
                :disabled="selected.length ? false : true"
                >
                Add
                </v-btn>
                <v-btn
                depressed
                @click="handleCloseDialog"
                >
                Cancel
                </v-btn>
         </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
import { changeFormat } from '@/utils/dateHelper';
import { payment_status } from  '@/helpers/payment_status';

export default {
    name:'DuplicateSentRequest',
    props: {
        open_modal: {
             type: Boolean,
        },
        items: {},
        company_id: {},
        billing_id: {},
        request_id: {},
        total_amount: {},
    },
    data () {
        return {
             dialog: false,
             total: 0,
             datas:[],
             selected:[],
             headers: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Date Created', value: 'created_at' },
                { text: 'Requestor', value: 'user.full_name' },
                { text: 'Project Name', value: 'project_name' },
                { text: 'Amount', value: 'net_premium' },
                { text: 'STATUS', value: 'payment_status' },
            ],
            //  headers: [
            //     {
            //         text: 'ID',
            //         align: 'start',
            //         sortable: false,
            //         value: 'id',
            //     },
            //     { text: 'DATE CREATED', value: 'created_at' },
            //     { text: 'REQUESTOR', value: 'requestor' },
            //     { text: 'IN CHARGE', value: 'in_charge' },
            //     { text: 'ENTITY', value: 'entity_id' },
            //     { text: 'NAME OF PROJECT', value: 'project_name' },
            //     { text: 'AMOUNT (NOA)', value: 'noa_amount' },
            //     { text: 'INSURED', value: 'insured_amount' },
            //     { text: 'NET PREMUIM', value: 'net_premium' },
            //     { text: 'INSURANCE STATUS', value: 'insurance_status' },
            //     { text: 'PAYMENT STATUS', value: 'payment_status' },
            // ],
            }
    },
    methods:{
        formatNumber(value) {
        // Format the number with commas
            if (typeof value !== 'string') {
                value = String(value || ''); // Ensure value is a string
            }
            const [integerPart, decimalPart] = value.split('.');
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

        },
        checkDialogClose(val) {
           if(!val){
               this.$emit('handleModalHide')
           }
       },
       handleGetPaymentStatus(id) {
         const status_payment = payment_status.find(item=>item.value == id);
         return status_payment.text
        },
       handleCloseDialog() {
            this.$emit('handleModalHide')
       },
       handleSumbit() {
            this.$emit('handleAddRelatedRequest',this.selected)
       },
       handleMarkAsPaid() {
            this.$emit('markAsPaid')
       },
       formattedDate(date) {
          return changeFormat(date); // Example format string
       },
       handleSendRequest(val) {
          this.$emit('sendBillRequest', val)
       },
       handleRemoveFromList(index) {
          this.datas.splice(index,1)
       },
       getRelatedRequest(index) {
            this.$notiflix.Loading.arrows();
             this.axios.get(`/request/related/${this.company_id}/${this.billing_id}?request_id=${this.request_id}`).then((res)=>{
                this.exclude_ids = res.data.data.data.filter(item => {
                    return item.request_sent && item.payment_status == 1 ;
                });
                this.has_to_send = res.data.data.data.filter(item => {
                    return item.payment_status == 1 ;
                });
                this.company_name = res.data.company
                this.billing_email = res.data.email
                this.datas = res.data.data.data
                this.total_page = res.data.data.total
             }).catch((err)=>{
                  console.log(err,'err')
             }).finally(()=>{
              this.$notiflix.Loading.remove();
             })
       },
    },

    watch: {
        'open_modal'(newValue) {
            this.dialog = newValue;
            if(newValue){
                this.getRelatedRequest();
            }
        },
        // 'total_amount'(newValue) {
        //     this.total = newValue
        // },
        'items'(newValue) {
           this.datas = newValue
        },
        'selected'(newValue) {
            this.total = 0;
            if(newValue.length){
                newValue.forEach((value) => {
                    this.total = this.total + value.net_premium
                });
            }
            // else{
            //     this.total = this.total_amount
            // }
           
        },
    }
}
</script>

<style scoped>
.request-duplicate-confirmation{
    border-radius: 20px !important;
}
table {
        width: 100%;
        border-collapse: collapse;
}
table * {
    font-size: 13px !important;
    color: #000;
}
td, th {
    border: 1px solid #ddd;
    padding: 8px;
    width: fit-content;
}
th {
    background-color: #f6f6f6;
    color: #000;
}
.files_required{
    border: solid 3px red;
}
.confirm-text{
    font-family: inherit !important;
    color: #000 !important;
    width: 100%;
    font-size: 15px;
    margin-bottom: 20px;
}
</style>