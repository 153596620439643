import { render, staticRenderFns } from "./ApplicationPage.vue?vue&type=template&id=3b7c15bd&scoped=true"
import script from "./ApplicationPage.vue?vue&type=script&lang=js"
export * from "./ApplicationPage.vue?vue&type=script&lang=js"
import style0 from "./ApplicationPage.vue?vue&type=style&index=0&id=3b7c15bd&prod&scoped=true&lang=css"
import style1 from "./ApplicationPage.vue?vue&type=style&index=1&id=3b7c15bd&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7c15bd",
  null
  
)

export default component.exports