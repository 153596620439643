<template>
    <div class="login-container">
      <div class="login-form">
        <div class="pa-4 text-center" id="drawer-wrapper">
            <v-img
            class=""
            draggable="true"
            max-width="270"
            src="/logo.png"
            ></v-img>
        </div>
        <v-form  class="login-form-inputs"
                ref="form"
                v-model="valid"
                lazy-validation
            >

                <div class="admin-login-form">
                    <v-text-field
                        v-model="payload.email"
                        :rules="emailRules"
                        label="E-mail"
                        dense
                        solo
                        outlined
                    ></v-text-field>

                    <v-text-field
                        v-model="payload.password"
                        :rules="passwordRules"
                        label="Password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        required
                        @click:append="showPassword = !showPassword"
                        dense
                        solo
                        outlined
                    ></v-text-field>
                </div>
                <v-btn
                color="success"
                class="mr-4 btn-submit"
                :loading="loading"
                @click="Login()"
                >
                Login
                </v-btn>
            </v-form>
      </div>
    </div>
  </template>
  
  <script>
    export default {
        name: 'AdminLogin',

        data: () => ({
            valid: true,
            loading:false,
            name: '',
            passwordRules: [
                v => !!v || 'Password is required',
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],

            select: null,
            checkbox: false,
            showPassword: false,
            payload:{
                email : null,
                password: null,
                is_admin: true
            }
        }),

        methods: {
            Login() {
                if(!this.$refs.form.validate()){
                    return false;
                }
                this.loading = true
                this.$store.dispatch('Login', this.payload).then((res) => {
                   this.$awn.success('Login Success')
                   this.$router.push('/')``
                }).catch((error) => {
                   console.log(error,'error')
                }).finally((error) => {
                    this.loading = false
                })
            },
        },
    }
</script>

<style lang="scss">
    .login-form-inputs{
        width: 100%;
    }
    .login-container{
      display: flex;
    }
    .login-form{
      min-width: 20%;
      box-shadow:- rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin: auto;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      border-radius: 10px;
    }
    .login-form h1{
     font-weight: 800;
    }
    .login-form button.btn-submit{
      width: 100%;
      background: blue !important;
      span {
        font-weight: 800;
      }
    }
    .admin-login-form .v-input .v-input__control .v-text-field__details > div .v-messages__wrapper{
        color: red !important;
    }
    .admin-login-form .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
        border: 2px solid red !important;
    }   
 
  </style>
  
  