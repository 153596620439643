<template>
    <div>
      <div class="position_center">
        <span v-if="msg === 'Verifying login. Please wait...'">
          <i class="el-icon-loading" /> {{ msg }}
        </span>
        <span v-else-if="msg === 'error'">
          {{ msg }}. Redirecting to Login... <!--<a :href="$etousoft_frontend_url">ETOUSOFT LOGIN</a>-->
        </span>
        <span v-else>
          {{ msg }}
        </span>
      </div>
    </div>
  </template>
  
  <script>
  import { EtousoftVerify } from '@/api/etousoft_verfication'
    // import { mapGetters } from 'vuex'
  import Cookies from 'js-cookie'
  
  export default {
    name: 'VerifyPage',
    data() {
      return {
        par: {},
        msg: 'Verifying login. Please wait...',
        default_settings: {
          SSInterval: 300,
          StillWorkingInterval: 420,
          image_blur: 0,
          record_urls: 1,
          manual_edit: 1,
          status: 'active',
          screenshots: 1,
          screenshots_deletion: 1
        },
        work_schedule: [
          {
            day: 'Monday',
            start: '08:00 AM',
            end: '06:00 PM',
            active: true
          },
          {
            day: 'Tuesday',
            start: '08:00 AM',
            end: '06:00 PM',
            active: true
          },
          {
            day: 'Wednesday',
            start: '08:00 AM',
            end: '06:00 PM',
            active: true
          },
          {
            day: 'Thursday',
            start: '08:00 AM',
            end: '06:00 PM',
            active: true
          },
          {
            day: 'Friday',
            start: '08:00 AM',
            end: '06:00 PM',
            active: true
          },
          {
            day: 'Saturday',
            start: '08:00 AM',
            end: '06:00 PM',
            active: true
          },
          {
            day: 'Sunday',
            start: '08:00 AM',
            end: '06:00 PM',
            active: true
          }
        ]
      }
    },
  
    created() {
      if (this.$route.query) {
        this.par = this.$route.query
        if (this.par.user_company_id && this.par.token) {
          EtousoftVerify({ id: this.par.user_company_id, token: this.par.token }).then(res => {
            if (res.data.status === 'verified') {
                Cookies.set('token',this.par.token)
                this.$store.dispatch('RegisterToken', this.par.token).then(() => {
                  setTimeout(() => {
                    this.LoginUser(res.data);
                  }, 1000)
                }).catch(error => {
                  console.log(error)
                  this.msg = 'error'
                  // setTimeout(() => {
                  //   this.$router.push({ name: 'LOGIN' })
                  // }, 2000)
                })
            } else {
              this.msg = 'error'
            }
          }).catch(error => {
            console.log(error)
            this.msg = 'error'
          })
        } else {
          this.msg = 'error'
        }
      } else {
        this.msg = 'error'

      }
    },
    methods: {
      LoginUser(params) {
        this.axios.post(`/user/other-info`,params).then((res)=>{
            if(res.status == 200){
               this.$router.push({name : 'home'})
            }
        }).catch((err)=>{
            console.log(err,'err')
        }).finally(()=>{
        })
      }
    }
  }
  </script>
  
  <style>
  .position_center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  </style>
  