export const insurance_status = [
    { value: 1, text: 'Draft' },
    { value: 2, text: 'Submitted to Aetos' },
    { value: 3, text: 'Submitted to Insurance Co.' },
    { value: 9, text: 'For Acceptance'},
    { value: 10, text: 'Accepted'},
    { value: 4, text: 'Issued-Done' },
    { value: 5, text: 'Issued-For Delivery'},
    { value: 6, text: 'Issued-Delivered'},
    { value: 7, text: 'Cancelled By Client'},
    { value: 8, text: 'Cancelled By Company'},
]

export default insurance_status;